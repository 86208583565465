<template>
<!-- eslint-disable -->
	<div>
		<div style="height: 750px;">
			<v-card
				class="mx-auto my-8"
				max-width="350"
				min-height="200"
			>
				<v-card-text>
					<v-row>
						<h3 class="pl-1">{{ content.updated_at.slice(0, 10) }}</h3>
						<v-spacer></v-spacer>
						<span v-if="content.updated_by != this.name" @click="menu = true; giftFlg = false" class="pr-1 pt-1" style="font-weight: bolder">{{ content.updated_by }}</span>
						<v-icon v-if="content.updated_by == this.name" @click="editFlg = true" class="pr-1 pt-1">mdi-pencil</v-icon>
						<v-icon v-if="content.updated_by == this.name" @click="trashFlg = true" class="pr-1 pt-1">mdi-delete</v-icon>
					</v-row>
					<div class="my-8">
						<p class="content" style="white-space: pre-wrap;">{{ content.content }}</p>
					</div>
				</v-card-text>
				
				<div class="pt-5">
					<vue-star style="position: absolute; right: 45px; bottom: -10px;">	
						<i slot="icon" @click="toggleFavorite(content.id)">
							<v-icon class="pb-6 mb-5" :style="this.favoriteFlg ? 'color:#FFD700' : 'color:#777777'" style="padding-left: 2.5px;">mdi-star</v-icon>
						</i>
					</vue-star>
					<vue-star :style="this.likeFlg ? 'pointer-events: none' : ''" style="position: absolute; right: 0px; bottom: -10px;">
						<i slot="icon" @click="addLike(content.id)">
							<v-icon class="pb-6 mb-5" :style="this.likeFlg ? 'color:red' : 'color:#777777'" style="padding-left: 2.5px;">mdi-heart</v-icon>
						</i>
					</vue-star>
					<span v-on:mouseover="hoverAction" v-on:mouseleave="hoverAction" style="position: absolute; right: 18px; bottom: 24.5px;">{{ like }}</span>
				</div>

			</v-card>
			<v-card v-if="hoverFlg" width="25%" class="mr-5" style="margin: 0 auto 60px auto;">
				<p v-for="item in this.likeUserArray" :key="item.id" class="text-center my-1"> {{ item }} </p>
			</v-card>
			


			<!-- ギフト -->
			<v-card
				v-for="gifted in giftedList"
				:key="gifted.id"
				class="mx-auto my-8"
				max-width="350"
			>
				<v-card-text>
					<div>
						<p class="content mb-0">{{ gifted.user }}さんが<br/>{{ gifted.amount }}takenoをプレゼントしました！</p>
					</div>
				</v-card-text>

			</v-card>

			<v-btn class="ml-5" @click="backHome()">＜ 戻る</v-btn>

			<v-btn v-if="content.updated_by != this.name" @click="menu = true; giftFlg = true" fab :color="this.color" style="position: absolute !important; right: 30px; bottom: 100px;"><v-icon color="white">mdi-gift</v-icon></v-btn>
			<v-menu
				v-model="menu"
				origin="center center"
				transition="scale-transition"
				offset-x
				offset-y
				min-width="auto"
				absolute
				nudge-bottom="150"
				nudge-right="42"
			>
				<v-card v-if="giftFlg" style="width: 330px;">
					<v-card-text class="text-center">
							友人の日報にtakenoを贈りましょう！<br/>
					</v-card-text>
					<v-card-actions>
						<v-btn class="ma-auto" v-for="amount in amountList" :key="amount.id" @click="gift(amount)">{{ amount }}</v-btn>
					</v-card-actions>
				</v-card>

				<!-- ユーザーアクションメニュー -->
				<v-card v-if="!giftFlg" style="width: 330px;">
					<v-card-actions>
						<v-btn class="ma-auto" v-for="action in actionList" :key="action.id" @click="act(action.action)">{{ action.name }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-menu>

		</div>

		<!-- ポップアップ(編集) -->
		<v-dialog
			v-model="editFlg"	
			persistent	
			max-width="90%"	
			style="z-index: 999;"	
		>	
			<v-card class="gradation">	
				<v-icon @click="editFlg = false" size="30px" color="black" class="mt-2" style="position: absolute; right: 10px;">mdi-close-circle-outline</v-icon>
				<v-col cols="8">
					<DatePicker :date="content.updated_at.slice(0, 10)" @changeDate="setDate"/>
				</v-col>
				<v-textarea	
					outlined	
					name="report"
					class="pt-6"	
					label="日報の編集"	
					v-model="content.content"	
					height="300px"	
					style="width:300px; margin: -30px auto 0 auto;"	
					v-on:keydown.enter="enterDown()"	
					v-bind:rules="upRules"	
				></v-textarea>	
				<v-card-actions>	
					<v-btn block tile color="red" dark depressed small @click="edit(content.id)" class="mb-2">	
					編集する	
					</v-btn>	
				</v-card-actions>	
			</v-card>	
		</v-dialog>

		<!-- ポップアップ(削除) -->
		<v-dialog
			v-model="trashFlg"
			persistent
			max-width="90%"
			style="z-index: 999;"
			>
			<v-card class="gradation">
				<v-icon @click="trashFlg = false" size="30px" color="black" class="mt-2" style="position: absolute; right: 10px;">mdi-close-circle-outline</v-icon>
				<div class="text-center pt-8 pb-4 pl-4">
					本当に削除しますか？
				</div>
				<v-card-actions>
					<v-btn block tile color="red" max-width="50" dark depressed small @click="trash(content.id)" class="mb-2">
						削除する
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
/*eslint-disable */
import * as api from '@/api.js'
import VueStar from 'vue-star'
import DatePicker from '@/components/DatePicker.vue'
export default {
	name: 'Detail',
  	components: { VueStar, DatePicker },
	data () {
		return {
		// 投稿内容
			content: '',
		// edit関連
			editFlg: false,	
			menu: false,	
			date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			upRules: [text => text.length <= 595 || '最大文字数は595文字です'],
		// trash関連
			trashFlg: false,
		// お気に入り
			favoriteUserArray:[],
			favoriteFlg: false,
		// いいね
			likeUserArray:[],
			likeFlg: false,
			like: 0,
			hoverFlg: false,
		// ギフト
			menu:false,
			giftFlg: false,
			amountList: [100, 50, 30, 10],
			giftedList: [],
		// ユーザーアクション
			actionList: [
				{
					name: 'ユーザーを非表示リストに追加',
					action: 'hidden'
				}
			]
		}
	},
	methods: {
		load() {
			this.content = this.$route.params.content
			console.log(this.$route.params.page)
			if(this.content) {
				this.like = this.content.like
				api.detail.getGift({ dailyId: this.content.id }).then((res) => {
					switch(res.data.status) {
						case 200:
							this.giftedList = res.data.content
							break
						case 500:
							this.pop('エラー', res.data.error[0])
							break
					}
				}).catch(this.$store.dispatch('nowloading', false))

				if(this.content.favorite_by) {
					this.favoriteUserArray = this.content.favorite_by
					this.turnOnFavorite()
				}
				if(this.content.liked_by) {
					this.likeUserArray = this.content.liked_by
					this.toggleLike()
				}
			}
		},
		toggleFavorite(id) {
			if (this.favoriteFlg) this.removeFavorite(id)	
			else if (!this.favoriteFlg) this.addFavorite(id)
		},
		addFavorite(id){
			api.detail.favorite({ id: id }).then((res) =>{
                switch(res.data.status) {
                    case 200:
						this.favoriteUserArray = res.data.content
						this.turnOnFavorite()
                        break
                    case 500:
						this.pop('エラー', res.data.error[0])
                        break
                }
			})
		},
		removeFavorite(id){
			api.detail.removeFav({ id: id }).then((res) =>{	
				switch(res.data.status) {	
					case 200:	
						this.favoriteUserArray = res.data.content	
						this.favoriteFlg = false	
					break	
					case 500:	
						this.pop('エラー', res.data.error[0])
					break	
				}	
			})	
		},
		// 星スタイルをオン
		turnOnFavorite() {
			this.favoriteUserArray = this.favoriteUserArray.replace('[','').replace(']','')
			this.favoriteUserArray = this.favoriteUserArray.split(',')
			this.favoriteUserArray.forEach(element => {
				if (element == this.name) this.favoriteFlg = true
			});
		},
		addLike(id){
			api.detail.like({ id: id }).then((res) =>{
                switch(res.data.status) {
                    case 200:
						this.like = res.data.content.like
						this.likeUserArray = res.data.content.liked_by
						this.toggleLike()
                        break
                    case 500:
						this.pop('エラー', res.data.error[0])
                        break
                }
			})
		},
		toggleLike() {
			this.likeUserArray = this.likeUserArray.replace('[','').replace(']','')
			this.likeUserArray = this.likeUserArray.split(',')
			this.likeUserArray.forEach(element => {
				if (element === this.name) this.likeFlg = true
			});
		},
		setDate(date) {
			this.date = date
		},
		edit(id) {
			api.detail.edit({ id: id, date: this.date, editContent: this.content.content }).then((res) =>{	
				switch(res.data.status) {	
					case 200:	
						this.pop('', res.data.content)	
						break	
					case 500:	
						this.pop('エラー', res.data.error[0])
						break	
				}	
			})	
		},
		trash(id) {
			api.detail.delete({ id: id }).then((res) =>{
				switch(res.data.status) {
					case 200:
						this.pop('', res.data.content)
						break
					case 500:
						this.pop('エラー', res.data.error[0])
						break
				}
			})
			this.trashFlg = false
		},
		gift(amount) {
			api.takeno.gift({ sendTo: this.content.updated_by, amount: amount, dailyId: this.content.id }).then((res) => {
				switch(res.data.status) {
					case 200:
						this.pop('', res.data.content)

						break
					case 500:
						this.pop('エラー', res.data.error[0])
						break
				}
			})
		},
		pop(title, message) {
			this.$store.dispatch('setDialog', { title: title, message: message });
		},

		backHome() {
			this.$router.push({ name: 'Home', params:{ page: this.$route.params.page } })
		},

		hoverAction() {
			this.hoverFlg = !this.hoverFlg
		},

		act(action) {
			switch(action){
				case 'hidden':
					this.$store.dispatch('nowLoading', true)
					api.setting.hidden({ user: this.content.updated_by }).then((res) => {
						switch(res.data.status){
							case 200:
								this.pop('', res.data.content)
								break
							case 500:
								this.pop('エラー', res.data.error[0])
								break
						}
					}).catch(this.$store.dispatch('nowLoading', false))
					break
			}
		},
	},
	created() {
		this.content = ''
		this.favoriteUserArray = []
		this.likeUserArray = []
		this.load()
	},
	computed: {
        // userId() {
        //     return this.$store.getters.loginInfo.userId
        // },
		name() {
            return this.$store.getters.loginInfo.name
		},
        color() {
            return this.$store.getters.loginInfo.color
        }
    },
}
</script>
<style>
    .VueStar__icon {
      font-size: 3em;
	  height: 30px;
	  width: 30px;
      cursor: pointer;
    }
</style>

